import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import moment, { Moment } from 'moment';
import _ from 'lodash';

@Component({
  selector: 'preview-tab-ticket',
  templateUrl: './preview-tab-ticket.component.html',
  styleUrls: ['./preview-tab-ticket.component.scss']
})
export class PreviewTabTicketComponent implements OnInit, OnChanges {
  @Input() ticketSelected: Ticket;
  @Input() isPreview1: boolean;
  @Input() isChooseDetailUpdate: boolean;
  @Input() isResponsive: boolean;
  @Input() focusOn: string;
  @Input() languageSelected: string;
  @Input() languageKey: string;
  @Input() mainColor: string;
  isQR: boolean;
  commonObject: Common;
  widthData: string;
  heightData: string;
  dataDisplaySize: DisplaySize;
  backgroundImageUrl: string;
  colorCircleLower: string;
  colorCircleUpper: string;
  colorCircleRotation: string;
  textColorTop: string;
  textColorBottom: string;
  textColorCircle: string;
  textColorTotalCount: string;
  adultCount: number;
  customCount1: number;
  customCount2: number;
  customCount3: number;
  totalCount: number;
  isOnlyAdult: boolean;
  type: string;
  ticketNameShort: any;
  subtitle: any;
  operatorName: any;
  usingPeriodType: any;
  currentDate: Moment;
  newDate: Moment;
  usingPeriod: any;
  textBottom: string;
  textButton: string;
  widthButton: number;
  xTextButton: number;
  xSvgButton: number;
  textLength: number;
  isReserve: boolean;
  ticketName: any;
  isMulti: boolean;
  scale: number;

  constructor(private sanitizer: DomSanitizer, private dataService: DataService, private commonService: CommonService) {
    this.commonObject = this.commonService.getCommonObject();
    this.currentDate = moment(Helper.getCurrentDateByTimeZone(this.commonObject));
    this.newDate = _.cloneDeep(this.currentDate).add(this.usingPeriod - 1, 'days');
    this.dataDisplaySize = {
      width: 375,
      height: 667
    };
    this.scale = 1;
    this.widthData = '375px';
    this.heightData = '667px';
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (data[1]) {
          this.currentDate = moment(Helper.getCurrentDateByTimeZone(this.commonObject));
          this.newDate = _.cloneDeep(this.currentDate).add(this.usingPeriod, 'days');
        }
      }
    });
    this.getTexBottomtAndTextButton();
    this.adultCount = 1;
    this.customCount1 = 1;
    this.totalCount = this.adultCount + this.customCount1;
  }

  ngDoCheck() {
    this.colorCircleLower = this.ticketSelected[`${this.type}`].colorCircleLower;
    this.colorCircleUpper = this.ticketSelected[`${this.type}`].colorCircleUpper;
    this.colorCircleRotation = this.ticketSelected[`${this.type}`].colorCircleRotation;
    this.textColorBottom = this.isPreview1
      ? this.ticketSelected[`${this.type}`].colorFontTicket1Bottom
      : this.ticketSelected[`${this.type}`].colorFontTicket2Bottom;
    this.textColorTop = this.isPreview1
      ? this.ticketSelected[`${this.type}`].colorFontTicket1Top
      : this.ticketSelected[`${this.type}`].colorFontTicket2Top;
    this.textColorCircle = this.isPreview1
      ? this.ticketSelected[`${this.type}`].colorFontTicket1Center
      : this.ticketSelected[`${this.type}`].colorFontTicket2Center;
    this.isOnlyAdult = this.customCount1 || this.customCount2 || this.customCount3 ? false : true;
    this.textColorTotalCount = this.isOnlyAdult ? this.textColorCircle : '#ff2aff';
    this.operatorName =
      this.isPreview1 && this.ticketSelected[`${this.type}`]?.operatorName
        ? this.ticketSelected[`${this.type}`]?.operatorName[`${this.languageSelected}`] ??
          this.ticketSelected[`${this.type}`]?.operatorName[`${this.languageKey}`]
        : this.ticketSelected[`${this.type}`]?.operatorName?.ja ?? '';
    if (this.isPreview1) {
      this.ticketNameShort =
        this.ticketSelected[`${this.type}`]?.ticketNameShort &&
        this.ticketSelected[`${this.type}`]?.ticketNameShort[`${this.languageSelected}`]
          ? this.ticketSelected[`${this.type}`]?.ticketNameShort[`${this.languageSelected}`]
          : '';
      this.ticketName =
        this.ticketSelected[`${this.type}`]?.ticketName && this.ticketSelected[`${this.type}`]?.ticketName[`${this.languageSelected}`]
          ? this.ticketSelected[`${this.type}`]?.ticketName[`${this.languageSelected}`]
          : '';
      this.subtitle =
        this.ticketSelected[`${this.type}`]?.subtitle && this.ticketSelected[`${this.type}`]?.subtitle[`${this.languageSelected}`]
          ? this.ticketSelected[`${this.type}`]?.subtitle[`${this.languageSelected}`]
          : '';
    } else {
      this.ticketNameShort = this.ticketSelected[`${this.type}`]?.ticketNameShort?.ja ?? '';
      this.ticketName = this.ticketSelected[`${this.type}`]?.ticketName?.ja ?? '';
      this.subtitle = this.ticketSelected[`${this.type}`]?.subtitle?.ja ?? '';
    }
    this.usingPeriodType = this.ticketSelected[`${this.type}`]?.usingPeriodType;
    this.usingPeriod = this.ticketSelected[`${this.type}`]?.usingPeriod ?? null;
    this.newDate = _.cloneDeep(this.currentDate).add(this.usingPeriod - 1, 'days');
    this.isReserve = this.ticketSelected[`${this.type}`]?.isReserve;
    this.isMulti = this.ticketSelected[`${this.type}`]?.sectionType;
    this.isQR = this.ticketSelected.ticketDetail?.qrType == 'single_scan';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isChooseDetailUpdate) {
      this.type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    }
    if (changes.languageSelected) {
      this.getTexBottomtAndTextButton();
    }
  }

  ngOnInit(): void {
    this.type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    this.backgroundImageUrl = this.ticketSelected.ticketDetail.image1;
  }

  /**
   * sanitized Background Image
   * @returns
   */
  sanitizedBackgroundImage(): SafeStyle {
    const imageUrl = this.isPreview1 ? this.ticketSelected[`${this.type}`].urlImage1 : this.ticketSelected[`${this.type}`].urlImage2;
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
    return sanitizedUrl;
  }

  /**
   * Set display size
   * @param data
   */
  setDisplaySizePreview(data: DisplaySize): void {
    const MAX_HEIGHT_RESPONSIVE = 667;
    const MAX_WIDTH_RESPONSIVE = 428;
    let height = data.height;
    let width = data.width;
    let scale = width / height;

    //height and width too big
    if (height > MAX_HEIGHT_RESPONSIVE || width > MAX_WIDTH_RESPONSIVE) {
      if (height < MAX_HEIGHT_RESPONSIVE) {
        this.scale = height / MAX_HEIGHT_RESPONSIVE;
        height = MAX_HEIGHT_RESPONSIVE;
      } else {
        this.scale = MAX_HEIGHT_RESPONSIVE / height;
        height = MAX_HEIGHT_RESPONSIVE;
        width = height * scale;
      }
      if (width > MAX_WIDTH_RESPONSIVE) {
        this.scale = MAX_WIDTH_RESPONSIVE / width;
        width = MAX_WIDTH_RESPONSIVE;
        height = width / scale;
      }
    } else {
      this.scale = 1;
    }
    this.dataDisplaySize['height'] = height;
    this.dataDisplaySize['width'] = width;
    this.heightData = `${height}px`;
    this.widthData = `${width}px`;
    this.getTexBottomtAndTextButton();
  }

  /**
   * getFontSizeTicketNameShort
   * @returns
   */
  getFontSizeTicketNameShort() {
    if (!this.ticketNameShort && !this.ticketName) {
      return;
    }
    const str = this.ticketNameShort ? this.ticketNameShort : this.ticketName;
    if (this.isQR) {
      const heightMain = document.getElementById('mid-qr').offsetHeight;
      const mainSize =
        this.dataDisplaySize.height > this.dataDisplaySize.width
          ? Math.min(600, Math.min(heightMain, +this.dataDisplaySize.width) * 0.7)
          : Math.min(600, Math.min(heightMain, +this.dataDisplaySize.width / 2) * 0.7);
      return Math.min(30, (2 * mainSize) / this.getByteLength(str));
    }
    return Math.min(30, (2 * this.getScaleCircleSize()) / this.getByteLength(str));
  }

  /**
   * getByteLength
   * @param {string} str
   * @returns
   */
  getByteLength(str) {
    if (!str) {
      return 0;
    }
    let result = 0;
    for (let i = 0; i < str.length; i++) {
      if (/[^\x00-\xff]|[mwMW]/.test(str.charAt(i))) {
        result += 2;
      } else {
        result += 1;
      }
    }
    return result;
  }

  /**
   * convertMinutesToTime
   * @param minutes
   * @returns
   */
  convertMinutesToTime(input: any) {
    if (!input) {
      return;
    }
    const minutes = +input;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');
    const formattedSeconds = '00';

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  /**
   * convertMinutesToTimeWithoutSeconds
   * @param minutes
   * @returns
   */
  convertMinutesToTimeWithoutSeconds(input: any) {
    if (!input) {
      return;
    }
    const minutes = +input;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }
  /**
   * getScaleCircleSize
   * @returns
   */
  getScaleCircleSize(): number {
    const heightMain = document.getElementById('main-circle').offsetHeight;
    const circleSize =
      this.dataDisplaySize.height > this.dataDisplaySize.width
        ? Math.min(600, Math.min(heightMain, +this.dataDisplaySize.width) * 0.8)
        : Math.min(600, Math.min(heightMain, +this.dataDisplaySize.width / 2) * 0.8);
    return circleSize;
  }

  /**
   * changeDisplay
   * @param value subtitle
   * @returns
   */
  changeDisplay(): boolean {
    if (!this.subtitle) {
      return true;
    }
    if (this.subtitle?.split('W')?.length > 10 && this.subtitle.length > 21) {
      return false;
    } else if (this.subtitle.length > 36) {
      return false;
    }
    return true;
  }

  /**
   * getMainHeight
   * @returns
   */
  getMainHeight() {
    const mainHeight = document.getElementById('main').offsetHeight;
    return mainHeight * ((0.6 * mainHeight) / (mainHeight + 20));
  }

  /**
   * getWidthSubtitle
   * @returns
   */
  checkWidthSubtitle(): boolean {
    if (!this.subtitle) {
      return false;
    }
    const canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = '16px notoSansJP';
    let text = this.subtitle;
    let textLength = context.measureText(text).width;
    const divSubtitle = document.getElementById('subtitle').offsetWidth;
    if (textLength > divSubtitle) {
      canvas.remove();
      return true;
    }
    canvas.remove();
    return false;
  }

  /**
   * getTexBottomtAndTextButton
   * @returns
   */
  getTexBottomtAndTextButton() {
    if (!this.languageSelected) {
      return;
    }
    switch (this.languageSelected) {
      case 'ja':
        this.textBottom = '乗務員又は係員へこの画面を見せてください。';
        this.textButton = '詳細情報';
        this.widthButton = 600;
        this.xTextButton = 180;
        this.xSvgButton = 350;
        this.textLength = 280;
        break;
      case 'en':
        this.textBottom = 'Please show this screen to the crew or staff.';
        this.textButton = 'Details';
        this.widthButton = 565;
        this.xTextButton = 162.5;
        this.xSvgButton = 315;
        this.textLength = 245;
        break;
      case 'ko':
        this.textBottom = '이 화면을 승무원이나 직원에게 보여주세요.';
        this.textButton = '상세 내용';
        this.widthButton = 590;
        this.xTextButton = 175;
        this.xSvgButton = 340;
        this.textLength = 270;
        break;
      case 'zh-TW':
        this.textBottom = '請出示本畫面給乘務員或工作人員。';
        this.textButton = '票券詳情';
        this.widthButton = 600;
        this.xTextButton = 180;
        this.xSvgButton = 350;
        this.textLength = 280;
        break;
      case 'zh':
        this.textBottom = '请出示本画面给乘务员或工作人员。';
        this.textButton = '票券详情';
        this.widthButton = 600;
        this.xTextButton = 180;
        this.xSvgButton = 350;
        this.textLength = 280;
        break;
      case 'vi':
        this.textBottom = 'Vui lòng cho nhân viên hoặc tiếp viên xem màn hình này.';
        this.textButton = 'Chi tiết';
        this.widthButton = 590;
        this.xTextButton = 175;
        this.xSvgButton = 340;
        this.textLength = 270;
        break;
      case 'th':
        this.textBottom = 'กรุณาแสดงหน้าจอนี้ต่อพนักงาน';
        this.textButton = 'รายละเอียด';
        this.widthButton = 640;
        this.xTextButton = 200;
        this.xSvgButton = 390;
        this.textLength = 320;
        break;
      default:
        if (this.languageKey == 'en') {
          this.textBottom = 'Please show this screen to the crew or staff.';
          this.textButton = 'Details';
          this.widthButton = 565;
          this.xTextButton = 162.5;
          this.xSvgButton = 315;
          this.textLength = 245;
        } else {
          this.textBottom = '乗務員又は係員へこの画面を見せてください。';
          this.textButton = '詳細情報';
          this.widthButton = 600;
          this.xTextButton = 180;
          this.xSvgButton = 350;
          this.textLength = 280;
        }
        break;
    }
    if (this.scale != 1) {
      this.widthButton = this.widthButton * 0.8;
      this.xTextButton = this.xTextButton * 0.8;
      this.xSvgButton = this.xSvgButton * 0.8;
      this.textLength = this.textLength * 0.8;
    }
  }

  /**
   * getHeightMain2 (vùng hiển thị count adult, custom 1,2,3)
   * @returns
   */
  getHeightMain2() {
    const mainHeight = document.getElementById('main').offsetHeight;
    const mainHeight1 = document.getElementById('main-1').offsetHeight;
    const mainHeightCircle = document.getElementById('main-circle').offsetHeight;
    if (this.dataDisplaySize.width > this.dataDisplaySize.height) {
      return (mainHeight / 2) * 0.397;
    }
    return (mainHeight - mainHeight1 - mainHeightCircle - 40) * 0.96;
  }
}

export interface DisplaySize {
  width: number;
  height: number;
}
